import { Building2, Briefcase, PenTool, DollarSign, Camera, Award, Users, FileText, Phone, Mail, Globe } from "lucide-react";
import { Question } from "./types";

export const supplierQuestions: Question[] = [
  {
    id: "business_name",
    title: "What's your business name?",
    type: "text",
    options: [],
    icon: <Building2 className="w-8 h-8" />,
    background: "#f0f4f8",
  },
  {
    id: "business_type",
    title: "What type of service do you provide?",
    type: "single",
    options: [
      { value: "venue", label: "Venue" },
      { value: "catering", label: "Catering" },
      { value: "photography", label: "Photography" },
      { value: "entertainment", label: "Entertainment" },
      { value: "decor", label: "Decoration" },
      { value: "other", label: "Other" },
    ],
    icon: <Briefcase className="w-8 h-8" />,
    background: "#e0f7fa",
  },
  {
    id: "description",
    title: "Describe your services",
    type: "text",
    options: [],
    icon: <FileText className="w-8 h-8" />,
    background: "#fff3e0",
    description: "Tell couples about what makes your service special",
  },
  {
    id: "pricing_range",
    title: "What's your pricing range?",
    type: "single",
    options: [
      { value: "$", label: "Budget-friendly ($)" },
      { value: "$$", label: "Mid-range ($$)" },
      { value: "$$$", label: "Premium ($$$)" },
      { value: "$$$$", label: "Luxury ($$$$)" },
      { value: "custom", label: "Custom quotes only" },
    ],
    icon: <DollarSign className="w-8 h-8" />,
    background: "#e8f5e9",
  },
  {
    id: "contact_phone",
    title: "What's your business phone number?",
    type: "text",
    options: [],
    icon: <Phone className="w-8 h-8" />,
    background: "#e3f2fd",
  },
  {
    id: "contact_email",
    title: "What's your business email?",
    type: "text",
    options: [],
    icon: <Mail className="w-8 h-8" />,
    background: "#f3e5f5",
  },
  {
    id: "website_url",
    title: "What's your website URL? (Optional)",
    type: "text",
    options: [],
    icon: <Globe className="w-8 h-8" />,
    background: "#fce4ec",
  },
  {
    id: "address",
    title: "What's your business address?",
    type: "text",
    options: [],
    icon: <Building2 className="w-8 h-8" />,
    background: "#fff3e0",
  },
  {
    id: "photos",
    title: "Upload photos of your work",
    type: "photos",
    options: [],
    icon: <Camera className="w-8 h-8" />,
    background: "#fce4ec",
    description: "Share up to 5 photos showcasing your best work",
  },
];