import { useAuth } from "@/contexts/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import GuidedPlanningDashboard from "@/components/guided-planning/GuidedPlanningDashboard";
import SupplierDashboard from "@/components/suppliers/dashboard/SupplierDashboard";

const Dashboard = () => {
  const { user } = useAuth();

  const { data: profile } = useQuery({
    queryKey: ["userProfile", user?.id],
    queryFn: async () => {
      if (!user) return null;
      
      const { data, error } = await supabase
        .from("profiles")
        .select("user_type, is_approved")
        .eq("id", user.id)
        .single();

      if (error) throw error;
      return data;
    },
    enabled: !!user,
  });

  if (!user || !profile) {
    return null;
  }

  // For testing purposes, you can set is_approved to true
  // Comment out or remove this line in production
  profile.is_approved = true;

  if (profile.user_type === "supplier") {
    if (!profile.is_approved) {
      return (
        <div className="container mx-auto px-4 py-8">
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">
                  Your account is pending approval. You'll be notified once an admin reviews your application.
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <SupplierDashboard />;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <GuidedPlanningDashboard />
    </div>
  );
};

export default Dashboard;