import { useState } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card } from '@/components/ui/card';
import { Link, useNavigate } from 'react-router-dom';
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { useToast } from '@/hooks/use-toast';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    userType: 'couple'
  });
  const [loading, setLoading] = useState(false);
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      console.log('Starting login process for:', formData.email);
      
      if (!formData.email || !formData.password) {
        throw new Error('Please fill in all fields');
      }

      await signIn(formData.email, formData.password);
      
      // Successfully signed in
      console.log('Login successful, navigating to dashboard');
      navigate(formData.userType === 'supplier' ? '/supplier/dashboard' : '/dashboard');
      
      toast({
        title: "Welcome back!",
        description: "You've successfully signed in.",
      });

    } catch (error: any) {
      console.error('Login process error:', error);
      
      // Extract the error message from the response
      let errorMessage = "Invalid email or password";
      if (error.message === 'Please fill in all fields') {
        errorMessage = error.message;
      }
      
      toast({
        variant: "destructive",
        title: "Error signing in",
        description: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-background p-4">
      <Card className="w-full max-w-md p-6 space-y-6">
        <div className="text-center">
          <h1 className="font-serif text-3xl text-secondary">Welcome Back</h1>
          <p className="text-muted-foreground mt-2">Sign in to your account</p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <RadioGroup
            defaultValue="couple"
            value={formData.userType}
            onValueChange={(value) => setFormData(prev => ({ ...prev, userType: value }))}
            className="flex justify-center gap-4 pb-4"
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="couple" id="couple" />
              <Label htmlFor="couple">Couple</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="supplier" id="supplier" />
              <Label htmlFor="supplier">Supplier</Label>
            </div>
          </RadioGroup>

          <div className="space-y-2">
            <Input
              type="email"
              value={formData.email}
              onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
              placeholder="Email"
              required
              className="w-full"
            />
          </div>

          <div className="space-y-2">
            <Input
              type="password"
              value={formData.password}
              onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
              placeholder="Password"
              required
              className="w-full"
            />
          </div>

          <Button
            type="submit"
            className="w-full"
            disabled={loading}
          >
            {loading ? 'Signing in...' : 'Sign In'}
          </Button>
        </form>

        <div className="text-center text-sm">
          <span className="text-muted-foreground">Don't have an account? </span>
          <Link 
            to={`/auth/signup?type=${formData.userType}`} 
            className="text-primary hover:underline"
          >
            Sign up
          </Link>
        </div>
      </Card>
    </div>
  );
};

export default Login;