import React, { useState } from 'react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/lib/supabase";
import { Image, X } from "lucide-react";

interface PhotoUploadQuestionProps {
  onAnswer: (photos: string[]) => void;
  onBack: () => void;
  description?: string;
}

export const PhotoUploadQuestion = ({
  onAnswer,
  onBack,
  description
}: PhotoUploadQuestionProps) => {
  const [uploading, setUploading] = useState(false);
  const [photos, setPhotos] = useState<string[]>([]);
  const { toast } = useToast();

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!event.target.files || event.target.files.length === 0) {
        return;
      }

      setUploading(true);
      const file = event.target.files[0];
      const fileExt = file.name.split('.').pop();
      const filePath = `${crypto.randomUUID()}.${fileExt}`;

      const { error: uploadError, data } = await supabase.storage
        .from('supplier-onboarding-photos')
        .upload(filePath, file);

      if (uploadError) {
        throw uploadError;
      }

      const { data: { publicUrl } } = supabase.storage
        .from('supplier-onboarding-photos')
        .getPublicUrl(filePath);

      setPhotos(prev => [...prev, publicUrl]);

      toast({
        title: "Success",
        description: "Photo uploaded successfully",
      });
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message,
      });
    } finally {
      setUploading(false);
    }
  };

  const removePhoto = (index: number) => {
    setPhotos(prev => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="space-y-4">
      {description && (
        <p className="text-sm text-muted-foreground">{description}</p>
      )}

      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {photos.map((photo, index) => (
          <div key={photo} className="relative group">
            <img
              src={photo}
              alt={`Uploaded photo ${index + 1}`}
              className="w-full h-32 object-cover rounded-lg"
            />
            <button
              onClick={() => removePhoto(index)}
              className="absolute top-2 right-2 p-1 bg-red-500 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
            >
              <X className="w-4 h-4 text-white" />
            </button>
          </div>
        ))}
        {photos.length < 5 && (
          <div className="flex items-center justify-center w-full h-32 border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600">
            <label className="flex flex-col items-center justify-center w-full h-full cursor-pointer">
              <Image className="w-8 h-8 text-gray-500 dark:text-gray-400" />
              <span className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                {uploading ? "Uploading..." : "Add photo"}
              </span>
              <Input
                type="file"
                className="hidden"
                accept="image/*"
                onChange={handleFileUpload}
                disabled={uploading}
              />
            </label>
          </div>
        )}
      </div>

      <div className="flex justify-between">
        <Button
          onClick={onBack}
          variant="outline"
          className="w-24"
        >
          Back
        </Button>
        <Button 
          onClick={() => onAnswer(photos)}
          className="w-24"
          variant="secondary"
          disabled={photos.length === 0}
        >
          Next
        </Button>
      </div>
    </div>
  );
};