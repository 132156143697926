import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Home, Users, DollarSign, CalendarDays, Store, BarChart, BookOpen, MessageSquare } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { useToast } from '@/hooks/use-toast';
import { useIsMobile } from '@/hooks/use-mobile';
import { ThemeToggle } from './theme/ThemeToggle';
import DesktopNavigation from './header/DesktopNavigation';
import DesktopActions from './header/DesktopActions';
import MobileMenu from './header/MobileMenu';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import type { NavigationItem } from './header/types';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { user, signOut } = useAuth();
  const { toast } = useToast();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const { data: profile } = useQuery({
    queryKey: ["userProfile", user?.id],
    queryFn: async () => {
      if (!user) return null;
      
      const { data, error } = await supabase
        .from("profiles")
        .select("user_type")
        .eq("id", user.id)
        .single();

      if (error) throw error;
      return data;
    },
    enabled: !!user,
  });

  const getNavigationItems = (): NavigationItem[] => {
    if (!user) {
      return [
        { name: 'Home', href: '/', icon: Home },
        { name: 'Solutions', href: '/solutions', icon: Store },
        { name: 'About Us', href: '/about', icon: Users },
      ];
    }

    if (profile?.user_type === 'supplier') {
      return [
        { name: 'Dashboard', href: '/supplier/dashboard', icon: BarChart },
        { name: 'Bookings', href: '/supplier/bookings', icon: BookOpen },
        { name: 'Messages', href: '/supplier/messages', icon: MessageSquare },
      ];
    }

    // Default navigation for couples (unchanged)
    return [
      { name: 'Dashboard', href: '/dashboard', icon: Home },
      { name: 'Guest List', href: '/guests', icon: Users },
      { name: 'Budget', href: '/budget', icon: DollarSign },
      { name: 'Timeline', href: '/timeline', icon: CalendarDays },
      { name: 'Suppliers', href: '/suppliers', icon: Store },
    ];
  };

  const handleLogout = async () => {
    try {
      await signOut();
      toast({
        title: "Logged out successfully",
        description: "You have been logged out of your account.",
      });
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
      toast({
        variant: "destructive",
        title: "Error logging out",
        description: "There was a problem logging out. Please try again.",
      });
    }
  };

  const navigationItems = getNavigationItems();

  return (
    <header className="bg-white/80 dark:bg-background/80 backdrop-blur-md border-b">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          {/* Logo - Left */}
          <div className="flex-shrink-0">
            <Link to="/" className="font-serif text-2xl text-secondary hover:text-secondary/90 transition-colors">
              WeddyDay
            </Link>
          </div>

          {/* Navigation - Center (Desktop only) */}
          <DesktopNavigation items={navigationItems} />

          {/* Actions - Right */}
          {isMobile ? (
            <div className="flex items-center gap-2">
              <ThemeToggle />
              <MobileMenu
                items={navigationItems}
                user={user}
                onLogout={handleLogout}
                isOpen={isMobileMenuOpen}
                onOpenChange={setIsMobileMenuOpen}
              />
            </div>
          ) : (
            <DesktopActions user={user} onLogout={handleLogout} />
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
