import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { OnboardingFlow } from "./onboarding/OnboardingFlow";
import { RegistrationScreen } from "./onboarding/RegistrationScreen";
import { useOnboarding } from "./onboarding/useOnboarding";

const Onboarding = () => {
  const [searchParams] = useSearchParams();
  const userType = searchParams.get('type') || 'couple';
  const [showRegistration, setShowRegistration] = useState(false);
  const [answers, setAnswers] = useState<Record<string, any>>({});
  
  const {
    email,
    setEmail,
    password,
    setPassword,
    isLoading,
    handleSignUpAndSavePreferences,
  } = useOnboarding();

  const handleOnboardingComplete = (answers: Record<string, any>) => {
    setAnswers(answers);
    setShowRegistration(true);
  };

  if (showRegistration) {
    return (
      <RegistrationScreen
        email={email}
        password={password}
        onEmailChange={setEmail}
        onPasswordChange={setPassword}
        onSubmit={() => handleSignUpAndSavePreferences(answers, userType)}
        isLoading={isLoading}
      />
    );
  }

  return <OnboardingFlow onComplete={handleOnboardingComplete} />;
};

export default Onboarding;