import React from 'react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

interface TextInputQuestionProps {
  question: {
    id: string;
    title: string;
  };
  currentAnswer: string;
  onAnswer: (value: string) => void;
  onBack: () => void;
  type?: 'text' | 'number';
}

export const TextInputQuestion = ({
  question,
  currentAnswer,
  onAnswer,
  onBack,
  type = 'text'
}: TextInputQuestionProps) => {
  const [inputValue, setInputValue] = React.useState(currentAnswer || '');

  const handleSubmit = () => {
    if (inputValue.trim()) {
      onAnswer(inputValue.trim());
    }
  };

  return (
    <div className="space-y-4">
      <Input
        type={type}
        placeholder="Enter your answer"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        className="bg-card dark:bg-card/80"
        min={type === 'number' ? "0" : undefined}
      />
      <div className="flex justify-between">
        <Button
          onClick={onBack}
          variant="outline"
          className="w-24"
        >
          Back
        </Button>
        <Button 
          onClick={handleSubmit}
          className="w-24"
          variant="secondary"
          disabled={!inputValue.trim()}
        >
          Next
        </Button>
      </div>
    </div>
  );
};