import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "@/contexts/AuthContext";
import { supabase } from "@/lib/supabase";
import { Card } from "@/components/ui/card";
import { Calendar } from "@/components/ui/calendar";
import { Button } from "@/components/ui/button";
import { format } from "date-fns";

export const SupplierAvailability = () => {
  const { user } = useAuth();
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);

  const { data: unavailableDates } = useQuery({
    queryKey: ["supplierAvailability", user?.id],
    queryFn: async () => {
      if (!user) return [];

      const { data, error } = await supabase
        .from("supplier_availability")
        .select("date")
        .eq("supplier_id", user.id)
        .eq("is_available", false);

      if (error) throw error;
      return data.map(d => new Date(d.date));
    },
    enabled: !!user,
  });

  const handleSaveAvailability = async () => {
    if (!user) return;

    const { error } = await supabase
      .from("supplier_availability")
      .insert(
        selectedDates.map(date => ({
          supplier_id: user.id,
          date: format(date, "yyyy-MM-dd"),
          is_available: false,
        }))
      );

    if (error) {
      console.error("Error saving availability:", error);
    } else {
      setSelectedDates([]);
    }
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-medium">Manage Availability</h3>
          {selectedDates.length > 0 && (
            <Button onClick={handleSaveAvailability}>
              Mark as Unavailable
            </Button>
          )}
        </div>
        
        <Calendar
          mode="multiple"
          selected={selectedDates}
          onSelect={setSelectedDates as any}
          disabled={unavailableDates}
          className="rounded-md border"
        />

        {unavailableDates?.length > 0 && (
          <div className="mt-4">
            <h4 className="text-sm font-medium mb-2">Unavailable Dates:</h4>
            <div className="flex flex-wrap gap-2">
              {unavailableDates.map((date, index) => (
                <span
                  key={index}
                  className="px-2 py-1 bg-gray-100 rounded-md text-sm"
                >
                  {format(date, "PP")}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};