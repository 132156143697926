import React from 'react';
import { TableCell, TableRow } from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { MessageSquare, Pencil, Trash2 } from 'lucide-react';
import GuestForm from '../GuestForm';
import { useIsMobile } from '@/hooks/use-mobile';
import { Badge } from '@/components/ui/badge';

interface GuestListRowProps {
  guest: any;
  isEditing: boolean;
  onEdit?: (guest: any) => void;
  onDelete: (id: string) => void;
  onUpdate: (formData: any) => void;
  onCancelEdit?: () => void;
  onSendSMS: (guest: any) => void;
}

const GuestListRow = ({
  guest,
  isEditing,
  onEdit,
  onDelete,
  onUpdate,
  onCancelEdit,
  onSendSMS,
}: GuestListRowProps) => {
  const isMobile = useIsMobile();

  if (isEditing) {
    return (
      <TableRow>
        <TableCell colSpan={isMobile ? 2 : 6}>
          <GuestForm
            guest={guest}
            onSubmit={onUpdate}
            onCancel={onCancelEdit}
          />
        </TableCell>
      </TableRow>
    );
  }

  if (isMobile) {
    return (
      <TableRow className="border-b hover:bg-muted/50">
        <TableCell className="p-4 w-full">
          <div className="flex flex-col space-y-3">
            <div className="flex items-start justify-between">
              <div>
                <h3 className="font-medium text-base">
                  {guest.first_name} {guest.last_name}
                </h3>
                {guest.email && (
                  <p className="text-sm text-muted-foreground mt-0.5">
                    {guest.email}
                  </p>
                )}
                {guest.phone && (
                  <p className="text-sm text-muted-foreground">
                    {guest.phone}
                  </p>
                )}
              </div>
              <div className="flex items-center space-x-1 shrink-0">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => onSendSMS(guest)}
                  disabled={!guest.phone}
                  title={guest.phone ? 'Send SMS' : 'No phone number'}
                  className="h-8 w-8 p-0"
                >
                  <MessageSquare className="h-4 w-4" />
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => onEdit?.(guest)}
                  className="h-8 w-8 p-0"
                >
                  <Pencil className="h-4 w-4" />
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => {
                    if (window.confirm('Are you sure you want to delete this guest?')) {
                      onDelete(guest.id);
                    }
                  }}
                  className="h-8 w-8 p-0"
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </div>
            </div>
            <div className="flex flex-wrap gap-2">
              <Badge 
                variant={
                  guest.status === 'confirmed' ? 'success' :
                  guest.status === 'declined' ? 'destructive' :
                  'secondary'
                }
                className="capitalize"
              >
                {guest.status || 'pending'}
              </Badge>
              {guest.plus_one && (
                <Badge variant="outline">
                  +1 Guest
                </Badge>
              )}
              {guest.dietary_restrictions && (
                <Badge variant="outline" className="text-xs">
                  {guest.dietary_restrictions}
                </Badge>
              )}
            </div>
          </div>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell>{guest.first_name} {guest.last_name}</TableCell>
      <TableCell>
        <div>{guest.email}</div>
        <div className="text-sm text-muted-foreground">{guest.phone}</div>
      </TableCell>
      <TableCell>
        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
          ${guest.status === 'confirmed' ? 'bg-green-100 text-green-800' :
          guest.status === 'declined' ? 'bg-red-100 text-red-800' :
          'bg-yellow-100 text-yellow-800'}`}>
          {guest.status || 'pending'}
        </span>
      </TableCell>
      <TableCell>{guest.plus_one ? 'Yes' : 'No'}</TableCell>
      <TableCell>
        {guest.invitation_sent_at ? (
          <span className="text-sm text-muted-foreground">
            {new Date(guest.invitation_sent_at).toLocaleDateString()}
          </span>
        ) : (
          <span className="text-sm text-muted-foreground">Not sent</span>
        )}
      </TableCell>
      <TableCell>
        <div className="flex space-x-2">
          <Button
            variant="ghost"
            size="sm"
            onClick={() => onSendSMS(guest)}
            disabled={!guest.phone}
            title={guest.phone ? 'Send SMS' : 'No phone number'}
          >
            <MessageSquare className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => onEdit?.(guest)}
          >
            <Pencil className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => {
              if (window.confirm('Are you sure you want to delete this guest?')) {
                onDelete(guest.id);
              }
            }}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default GuestListRow;