import { useQuery } from "@tanstack/react-query";
import { useAuth } from "@/contexts/AuthContext";
import { supabase } from "@/lib/supabase";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useForm } from "react-hook-form";
import { useToast } from "@/hooks/use-toast";

interface SupplierProfileForm {
  business_name: string;
  business_type: string;
  website_url?: string;
  description?: string;
  pricing_range?: string;
  contact_phone?: string;
  contact_email?: string;
  business_address?: string;
}

export const SupplierProfile = () => {
  const { user } = useAuth();
  const { toast } = useToast();
  const form = useForm<SupplierProfileForm>();

  const { data: profile } = useQuery({
    queryKey: ["supplierProfile", user?.id],
    queryFn: async () => {
      if (!user) return null;

      const { data, error } = await supabase
        .from("supplier_profiles")
        .select("*")
        .eq("id", user.id)
        .single();

      if (error) throw error;
      return data;
    },
    enabled: !!user,
    meta: {
      onSuccess: (data: SupplierProfileForm | null) => {
        if (data) {
          form.reset(data);
        }
      },
    },
  });

  const onSubmit = async (data: SupplierProfileForm) => {
    try {
      const { error } = await supabase
        .from("supplier_profiles")
        .upsert({
          id: user?.id,
          ...data,
        });

      if (error) throw error;

      toast({
        title: "Success",
        description: "Profile updated successfully",
      });
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message,
      });
    }
  };

  return (
    <Card className="p-6">
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-2">
            <label className="text-sm font-medium">Business Name</label>
            <Input {...form.register("business_name")} />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium">Business Type</label>
            <Input {...form.register("business_type")} />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium">Website URL</label>
            <Input {...form.register("website_url")} />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium">Pricing Range</label>
            <Input {...form.register("pricing_range")} />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium">Contact Phone</label>
            <Input {...form.register("contact_phone")} />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium">Contact Email</label>
            <Input {...form.register("contact_email")} />
          </div>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Business Address</label>
          <Input {...form.register("business_address")} />
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Description</label>
          <Textarea {...form.register("description")} />
        </div>

        <Button type="submit">Save Changes</Button>
      </form>
    </Card>
  );
};