import React from 'react';
import { TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { useIsMobile } from '@/hooks/use-mobile';

const GuestListHeader = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <TableHeader>
        <TableRow>
          <TableHead>Guest Details</TableHead>
          <TableHead className="text-right">Actions</TableHead>
        </TableRow>
      </TableHeader>
    );
  }

  return (
    <TableHeader>
      <TableRow>
        <TableHead>Name</TableHead>
        <TableHead>Contact</TableHead>
        <TableHead>Status</TableHead>
        <TableHead>Plus One</TableHead>
        <TableHead>Last SMS</TableHead>
        <TableHead>Actions</TableHead>
      </TableRow>
    </TableHeader>
  );
};

export default GuestListHeader;