import React from 'react';
import { TableCell, TableRow } from '@/components/ui/table';
import { useIsMobile } from '@/hooks/use-mobile';
import { UserPlus2 } from 'lucide-react';

interface GuestListEmptyProps {
  isLoading: boolean;
}

const GuestListEmpty = ({ isLoading }: GuestListEmptyProps) => {
  const isMobile = useIsMobile();
  
  return (
    <TableRow>
      <TableCell 
        colSpan={isMobile ? 2 : 6} 
        className="h-[200px] text-center"
      >
        <div className="flex flex-col items-center justify-center space-y-3 text-muted-foreground p-8">
          {isLoading ? (
            <div className="animate-pulse">Loading...</div>
          ) : (
            <>
              <UserPlus2 className="h-8 w-8 opacity-50" />
              <div>No guests added yet</div>
              <div className="text-xs">
                Click the "Add Guest" button to get started
              </div>
            </>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default GuestListEmpty;