import { Card } from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "@/contexts/AuthContext";
import { supabase } from "@/lib/supabase";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import { startOfMonth, format, subMonths } from "date-fns";

export const BookingAnalytics = () => {
  const { user } = useAuth();

  const { data: analyticsData } = useQuery({
    queryKey: ["bookingAnalytics", user?.id],
    queryFn: async () => {
      if (!user) return null;

      console.log('Fetching booking analytics for user:', user.id);

      // Get bookings for the last 6 months
      const startDate = startOfMonth(subMonths(new Date(), 5));

      const { data: bookings, error } = await supabase
        .from("supplier_bookings")
        .select("event_date, status")
        .eq("supplier_id", user.id)
        .gte("event_date", startDate.toISOString());

      if (error) {
        console.error('Error fetching booking analytics:', error);
        throw error;
      }

      console.log('Fetched bookings for analytics:', bookings);

      // Group bookings by month
      const monthlyData = Array.from({ length: 6 }, (_, i) => {
        const date = startOfMonth(subMonths(new Date(), i));
        const monthBookings = bookings?.filter(b => 
          startOfMonth(new Date(b.event_date)).getTime() === date.getTime()
        ) || [];

        return {
          month: format(date, "MMM"),
          total: monthBookings.length,
          confirmed: monthBookings.filter(b => b.status === "confirmed").length,
          pending: monthBookings.filter(b => b.status === "pending").length
        };
      }).reverse();

      return monthlyData;
    },
    enabled: !!user
  });

  if (!analyticsData) return null;

  return (
    <Card className="p-6">
      <h3 className="text-lg font-semibold mb-4">Booking Trends</h3>
      <div className="h-[300px]">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={analyticsData}>
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="confirmed" fill="#22c55e" name="Confirmed" />
            <Bar dataKey="pending" fill="#eab308" name="Pending" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};