import { useQuery } from "@tanstack/react-query";
import { useAuth } from "@/contexts/AuthContext";
import { supabase } from "@/lib/supabase";
import { Card } from "@/components/ui/card";
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { BookingFilters } from "./BookingFilters";
import { BookingCard } from "./BookingCard";

export const SupplierBookings = () => {
  const { user } = useAuth();
  const { toast } = useToast();
  const [statusFilter, setStatusFilter] = useState<string>("all");
  const [searchQuery, setSearchQuery] = useState("");

  const { data: bookings, refetch } = useQuery({
    queryKey: ["supplierBookings", user?.id],
    queryFn: async () => {
      if (!user) return [];

      const { data, error } = await supabase
        .from("supplier_bookings")
        .select(`
          *,
          profiles:user_id (email, id)
        `)
        .eq("supplier_id", user.id)
        .order("event_date", { ascending: true });

      if (error) {
        console.error("Error fetching bookings:", error);
        throw error;
      }
      return data;
    },
    enabled: !!user,
  });

  const handleStatusUpdate = async (bookingId: string, newStatus: string) => {
    const { error } = await supabase
      .from("supplier_bookings")
      .update({ status: newStatus })
      .eq("id", bookingId);

    if (error) {
      console.error("Error updating booking status:", error);
      toast({
        title: "Error",
        description: "Failed to update booking status",
        variant: "destructive",
      });
    } else {
      toast({
        title: "Success",
        description: "Booking status updated successfully",
      });
      refetch();
    }
  };

  const filteredBookings = bookings?.filter((booking) => {
    const matchesStatus = statusFilter === "all" || booking.status === statusFilter;
    const matchesSearch =
      searchQuery === "" ||
      booking.profiles?.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      booking.notes?.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesStatus && matchesSearch;
  });

  if (!bookings?.length) {
    return (
      <Card className="p-6">
        <p className="text-center text-muted-foreground">No bookings found.</p>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      <BookingFilters
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />

      <div className="grid gap-4">
        {filteredBookings?.map((booking) => (
          <BookingCard
            key={booking.id}
            booking={booking}
            onStatusUpdate={handleStatusUpdate}
          />
        ))}
      </div>
    </div>
  );
};