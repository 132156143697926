import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import Landing from "@/pages/Landing";
import Solutions from "@/pages/Solutions";
import About from "@/pages/About";
import Index from "@/pages/Index";
import Login from "@/pages/auth/Login";
import Onboarding from "@/pages/auth/Onboarding";
import Budget from "@/pages/Budget";
import Guests from "@/pages/Guests";
import Suppliers from "@/pages/Suppliers";
import Timeline from "@/pages/Timeline";
import WeddingDetails from "@/pages/WeddingDetails";
import Header from "@/components/Header";
import Dashboard from "@/components/Dashboard";
import { SupplierBookings } from "@/components/suppliers/dashboard/SupplierBookings";

const AppRoutes = () => {
  const { user } = useAuth();
  
  const { data: profile, isLoading } = useQuery({
    queryKey: ["userProfile", user?.id],
    queryFn: async () => {
      if (!user) return null;
      
      const { data, error } = await supabase
        .from("profiles")
        .select("user_type")
        .eq("id", user.id)
        .maybeSingle();

      if (error) {
        console.error('Error fetching user profile:', error);
        throw error;
      }
      
      console.log('User profile data:', data);
      return data;
    },
    enabled: !!user,
  });

  const isSupplier = () => {
    return profile?.user_type === 'supplier';
  };

  const redirectToDashboard = () => {
    if (!user || isLoading) return null;
    
    if (isSupplier()) {
      console.log('Redirecting supplier to supplier dashboard');
      return <Navigate to="/supplier/dashboard" replace />;
    }
    
    if (profile?.user_type === 'couple') {
      console.log('Redirecting couple to couple dashboard');
      return <Navigate to="/dashboard" replace />;
    }
    
    console.log('User type not set, redirecting to login');
    return <Navigate to="/auth/login" replace />;
  };

  const protectCoupleRoute = (component: JSX.Element) => {
    if (!user) {
      console.log('No user, redirecting to login');
      return <Navigate to="/auth/login" replace />;
    }
    
    if (isLoading) return null;
    
    if (profile?.user_type !== 'couple') {
      console.log('Non-couple user trying to access couple route');
      if (isSupplier()) {
        return <Navigate to="/supplier/dashboard" replace />;
      }
      return <Navigate to="/auth/login" replace />;
    }
    
    return component;
  };

  const protectSupplierRoute = (component: JSX.Element) => {
    if (!user) {
      console.log('No user, redirecting to login');
      return <Navigate to="/auth/login" replace />;
    }
    
    if (isLoading) return null;
    
    if (profile?.user_type !== 'supplier') {
      console.log('Non-supplier user trying to access supplier route');
      if (profile?.user_type === 'couple') {
        return <Navigate to="/dashboard" replace />;
      }
      return <Navigate to="/auth/login" replace />;
    }
    
    return component;
  };
  
  if (isLoading) {
    return null;
  }

  return (
    <>
      <Header />
      <Routes>
        {/* Public routes */}
        <Route path="/" element={!user ? <Landing /> : redirectToDashboard()} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/about" element={<About />} />
        <Route path="/auth/login" element={!user ? <Login /> : redirectToDashboard()} />
        <Route path="/auth/signup" element={!user ? <Onboarding /> : redirectToDashboard()} />

        {/* Couple routes */}
        <Route path="/dashboard" element={protectCoupleRoute(<Index />)} />
        <Route path="/budget" element={protectCoupleRoute(<Budget />)} />
        <Route path="/guests" element={protectCoupleRoute(<Guests />)} />
        <Route path="/suppliers" element={protectCoupleRoute(<Suppliers />)} />
        <Route path="/timeline" element={protectCoupleRoute(<Timeline />)} />
        <Route path="/wedding-details" element={protectCoupleRoute(<WeddingDetails />)} />

        {/* Supplier routes */}
        <Route path="/supplier/dashboard" element={protectSupplierRoute(<Dashboard />)} />
        <Route path="/supplier/bookings" element={protectSupplierRoute(<SupplierBookings />)} />

        {/* Catch all route */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
};

export default AppRoutes;