import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import { useAuth } from '@/contexts/AuthContext';
import type { Supplier } from '@/components/suppliers/types';

export const useSuppliers = (category?: string) => {
  const { user } = useAuth();

  return useQuery({
    queryKey: ['suppliers', category, user?.id],
    queryFn: async () => {
      console.log('Fetching suppliers for category:', category);
      console.log('Current user:', user?.id);
      
      let query = supabase
        .from('suppliers')
        .select('*');
      
      if (category) {
        console.log('Filtering by category:', category);
        query = query.eq('category', category);
      }
      
      console.log('Executing suppliers query...');
      
      const { data, error } = await query;
      
      if (error) {
        console.error('Error fetching suppliers:', error);
        throw error;
      }
      
      console.log('Fetched suppliers:', data);
      return data as Supplier[];
    },
    enabled: true,
    retry: 1,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
};