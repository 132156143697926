import { ReactNode } from "react";
import { OnboardingCard } from "./OnboardingCard";

interface OnboardingContainerProps {
  children: ReactNode;
  className?: string;
}

export const OnboardingContainer = ({ children, className = "" }: OnboardingContainerProps) => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-background p-4">
      <OnboardingCard className={className}>
        {children}
      </OnboardingCard>
    </div>
  );
};