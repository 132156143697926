import { Loader } from "lucide-react";
import { useAuth } from "@/contexts/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { BookingAnalytics } from "./BookingAnalytics";
import { SupplierBookings } from "./SupplierBookings";
import { SupplierAvailability } from "./SupplierAvailability";
import { SupplierProfile } from "./SupplierProfile";
import { SupplierMetrics } from "./SupplierMetrics";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const SupplierDashboard = () => {
  const { user } = useAuth();

  const { data: profile, isLoading: profileLoading } = useQuery({
    queryKey: ["supplierProfile", user?.id],
    queryFn: async () => {
      if (!user) return null;
      
      console.log('Fetching supplier profile for:', user.id);
      const { data, error } = await supabase
        .from("supplier_profiles")
        .select("*")
        .eq("id", user.id)
        .maybeSingle();

      if (error) {
        console.error('Error fetching supplier profile:', error);
        throw error;
      }
      
      console.log('Supplier profile data:', data);
      return data;
    },
    enabled: !!user,
  });

  if (profileLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 space-y-6">
      <div className="bg-card dark:bg-gray-800/50 rounded-lg shadow-soft dark:shadow-none p-6">
        <h1 className="text-2xl font-serif mb-6 text-foreground">Supplier Dashboard</h1>
        <SupplierMetrics />
      </div>

      <BookingAnalytics />

      <Tabs defaultValue="bookings" className="space-y-4">
        <TabsList className="w-full justify-start bg-background dark:bg-gray-800/50">
          <TabsTrigger 
            value="bookings"
            className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
          >
            Bookings
          </TabsTrigger>
          <TabsTrigger 
            value="availability"
            className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
          >
            Availability
          </TabsTrigger>
          <TabsTrigger 
            value="profile"
            className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
          >
            Profile
          </TabsTrigger>
        </TabsList>

        <TabsContent value="bookings">
          <SupplierBookings />
        </TabsContent>

        <TabsContent value="availability">
          <SupplierAvailability />
        </TabsContent>

        <TabsContent value="profile">
          <SupplierProfile />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default SupplierDashboard;