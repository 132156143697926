import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { useAuth } from "@/contexts/AuthContext";
import { supabase } from "@/lib/supabase";

export const useOnboarding = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();
  const { signUp } = useAuth();

  const handleSignUpAndSavePreferences = async (answers: Record<string, any>, userType: string) => {
    try {
      setIsLoading(true);
      console.log('Starting signup process...', { answers, userType });
      
      // Create the user account with additional metadata
      const signUpData = {
        email,
        password,
        options: {
          data: {
            user_type: userType,
            business_name: answers.business_name,
            business_type: answers.business_type,
            description: answers.description,
            pricing_range: answers.pricing_range,
            contact_phone: answers.contact_phone,
            contact_email: answers.contact_email || email,
            website_url: answers.website_url,
            address: answers.address,
            photos: answers.photos || [],
          }
        }
      };

      await signUp(email, password, signUpData.options);
      
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) throw new Error("Failed to create account");

      console.log('User created successfully with metadata:', signUpData.options.data);

      toast({
        title: "Welcome aboard! 🎉",
        description: userType === 'supplier' 
          ? "Your supplier account has been created. You can now manage your listing!"
          : "Your account has been created and preferences saved. Let's start planning your perfect day!",
      });

      navigate("/");
    } catch (error: any) {
      console.error("Error during signup:", error);
      toast({
        variant: "destructive",
        title: "Uh oh!",
        description: error.message || "Something went wrong while creating your account.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    isLoading,
    handleSignUpAndSavePreferences,
  };
};