import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { questions } from "./questions";
import { supplierQuestions } from "./supplierQuestions";
import { DateQuestion } from "./DateQuestion";
import { SingleChoiceQuestion } from "./SingleChoiceQuestion";
import { MultipleChoiceQuestion } from "./MultipleChoiceQuestion";
import { TextInputQuestion } from "./TextInputQuestion";
import { PhotoUploadQuestion } from "./PhotoUploadQuestion";
import { QuestionHeader } from "./QuestionHeader";
import { OnboardingContainer } from "./OnboardingContainer";
import { Calendar } from "lucide-react";

interface OnboardingFlowProps {
  onComplete: (answers: Record<string, any>) => void;
}

export const OnboardingFlow = ({ onComplete }: OnboardingFlowProps) => {
  const [searchParams] = useSearchParams();
  const userType = searchParams.get('type') || 'couple';
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState<Record<string, any>>({});
  const [date, setDate] = useState<Date>();

  const currentQuestions = userType === 'supplier' ? supplierQuestions : questions;
  const currentQuestion = currentQuestions[currentStep];

  // Early return with loading state if no currentQuestion
  if (!currentQuestion) {
    return (
      <OnboardingContainer>
        <div className="p-8 space-y-6">
          <div>Loading...</div>
        </div>
      </OnboardingContainer>
    );
  }

  const handleDateSelect = (selectedDate: Date | undefined) => {
    setDate(selectedDate);
    if (selectedDate) {
      setAnswers((prev) => ({ ...prev, wedding_date: selectedDate }));
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleAnswer = (value: string | string[]) => {
    setAnswers((prev) => ({ ...prev, [currentQuestions[currentStep].id]: value }));
    if (currentStep < currentQuestions.length - 1) {
      setCurrentStep((prev) => prev + 1);
    } else {
      onComplete(answers);
    }
  };

  const handleMultipleSelect = (value: string) => {
    const currentAnswers = answers[currentQuestion.id] || [];
    const updatedAnswers = currentAnswers.includes(value)
      ? currentAnswers.filter((answer: string) => answer !== value)
      : [...currentAnswers, value];
    
    setAnswers((prev) => ({ ...prev, [currentQuestion.id]: updatedAnswers }));
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const renderQuestion = () => {
    if (currentStep === 0 && userType !== 'supplier') {
      return (
        <DateQuestion
          date={date}
          onDateSelect={handleDateSelect}
          onSkip={() => {
            setAnswers((prev) => ({ ...prev, wedding_date: null }));
            setCurrentStep((prev) => prev + 1);
          }}
          onBack={currentStep > 0 ? handleBack : undefined}
        />
      );
    }

    switch (currentQuestion.type) {
      case "text":
      case "number":
        return (
          <TextInputQuestion
            question={currentQuestion}
            currentAnswer={answers[currentQuestion.id]}
            onAnswer={handleAnswer}
            onBack={handleBack}
            type={currentQuestion.type}
          />
        );
      case "multiple":
        return (
          <MultipleChoiceQuestion
            question={currentQuestion}
            selectedAnswers={answers[currentQuestion.id] || []}
            onAnswer={handleMultipleSelect}
            onNext={() => setCurrentStep((prev) => prev + 1)}
            onBack={handleBack}
          />
        );
      case "photos":
        return (
          <PhotoUploadQuestion
            onAnswer={handleAnswer}
            onBack={handleBack}
            description={currentQuestion.description}
          />
        );
      default:
        return (
          <SingleChoiceQuestion
            question={currentQuestion}
            currentAnswer={answers[currentQuestion.id]}
            onAnswer={handleAnswer}
            onBack={handleBack}
          />
        );
    }
  };

  return (
    <OnboardingContainer>
      <div className="p-8 space-y-6">
        <QuestionHeader
          currentStep={currentStep}
          totalSteps={currentQuestions.length}
          icon={currentQuestion.icon || <Calendar className="w-8 h-8" />}
          title={currentQuestion.title}
        />
        {renderQuestion()}
      </div>
    </OnboardingContainer>
  );
};