import { format } from "date-fns";
import { CalendarIcon, Clock, Mail } from "lucide-react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";

interface BookingCardProps {
  booking: any;
  onStatusUpdate: (bookingId: string, newStatus: string) => void;
}

export const BookingCard = ({ booking, onStatusUpdate }: BookingCardProps) => {
  return (
    <Card className="p-6 bg-card dark:bg-gray-800/50 border dark:border-gray-700">
      <div className="flex flex-col gap-4">
        <div className="flex items-start justify-between">
          <div className="space-y-2">
            <div className="flex items-center gap-2 text-muted-foreground">
              <CalendarIcon className="h-4 w-4" />
              <span className="font-medium text-foreground dark:text-gray-200">
                {format(new Date(booking.event_date), "PPP")}
              </span>
            </div>
            <div className="flex items-center gap-2 text-muted-foreground">
              <Mail className="h-4 w-4" />
              <span className="dark:text-gray-300">{booking.profiles?.email}</span>
            </div>
            {booking.amount && (
              <div className="flex items-center gap-2 text-muted-foreground">
                <Clock className="h-4 w-4" />
                <span className="dark:text-gray-300">Amount: ${booking.amount}</span>
              </div>
            )}
            {booking.notes && (
              <p className="text-sm text-muted-foreground dark:text-gray-400 mt-2">
                Notes: {booking.notes}
              </p>
            )}
          </div>
          <div className="flex flex-col items-end gap-2">
            <div
              className={`px-3 py-1 rounded-full text-sm ${
                booking.status === "confirmed"
                  ? "bg-green-100 dark:bg-green-900/50 text-green-800 dark:text-green-200"
                  : booking.status === "pending"
                  ? "bg-yellow-100 dark:bg-yellow-900/50 text-yellow-800 dark:text-yellow-200"
                  : booking.status === "completed"
                  ? "bg-blue-100 dark:bg-blue-900/50 text-blue-800 dark:text-blue-200"
                  : "bg-red-100 dark:bg-red-900/50 text-red-800 dark:text-red-200"
              }`}
            >
              {booking.status}
            </div>
            <div className="flex gap-2 mt-2">
              {booking.status === "pending" && (
                <>
                  <Button
                    size="sm"
                    onClick={() => onStatusUpdate(booking.id, "confirmed")}
                  >
                    Confirm
                  </Button>
                  <Button
                    size="sm"
                    variant="destructive"
                    onClick={() => onStatusUpdate(booking.id, "cancelled")}
                  >
                    Decline
                  </Button>
                </>
              )}
              {booking.status === "confirmed" && (
                <Button
                  size="sm"
                  onClick={() => onStatusUpdate(booking.id, "completed")}
                >
                  Mark Complete
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};