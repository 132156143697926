import { Check } from "lucide-react";
import { RegistrationForm } from "./RegistrationForm";
import { OnboardingContainer } from "./OnboardingContainer";

interface RegistrationScreenProps {
  email: string;
  password: string;
  onEmailChange: (email: string) => void;
  onPasswordChange: (password: string) => void;
  onSubmit: () => void;
  isLoading: boolean;
}

export const RegistrationScreen = ({
  email,
  password,
  onEmailChange,
  onPasswordChange,
  onSubmit,
  isLoading,
}: RegistrationScreenProps) => {
  return (
    <OnboardingContainer className="p-8 space-y-6">
      <div className="text-center space-y-4">
        <div className="mx-auto w-16 h-16 bg-primary dark:bg-primary/80 rounded-full flex items-center justify-center">
          <Check className="w-8 h-8 text-primary-foreground" />
        </div>
        <h1 className="font-serif text-3xl text-foreground">Almost there!</h1>
        <p className="text-muted-foreground">Create your account to save your preferences</p>
      </div>

      <RegistrationForm
        email={email}
        password={password}
        onEmailChange={onEmailChange}
        onPasswordChange={onPasswordChange}
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    </OnboardingContainer>
  );
};