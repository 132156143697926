import { Card } from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "@/contexts/AuthContext";
import { supabase } from "@/lib/supabase";
import { ChartBar, Calendar, Users, DollarSign } from "lucide-react";

export const SupplierMetrics = () => {
  const { user } = useAuth();

  const { data: metrics } = useQuery({
    queryKey: ["supplierMetrics", user?.id],
    queryFn: async () => {
      if (!user) return null;

      console.log('Fetching supplier metrics for user:', user.id);

      const { data: bookings, error: bookingsError } = await supabase
        .from("supplier_bookings")
        .select("status, amount")
        .eq("supplier_id", user.id);

      if (bookingsError) {
        console.error('Error fetching bookings:', bookingsError);
        throw bookingsError;
      }

      console.log('Fetched bookings:', bookings);

      const totalBookings = bookings?.length || 0;
      const confirmedBookings = bookings?.filter(b => b.status === "confirmed").length || 0;
      const pendingBookings = bookings?.filter(b => b.status === "pending").length || 0;
      const totalRevenue = bookings?.reduce((sum, booking) => sum + (booking.amount || 0), 0) || 0;

      return {
        totalBookings,
        confirmedBookings,
        pendingBookings,
        totalRevenue
      };
    },
    enabled: !!user
  });

  const metricCards = [
    {
      title: "Total Bookings",
      value: metrics?.totalBookings || 0,
      icon: <ChartBar className="h-5 w-5 text-primary" />
    },
    {
      title: "Confirmed",
      value: metrics?.confirmedBookings || 0,
      icon: <Calendar className="h-5 w-5 text-green-500" />
    },
    {
      title: "Pending",
      value: metrics?.pendingBookings || 0,
      icon: <Users className="h-5 w-5 text-yellow-500" />
    },
    {
      title: "Revenue",
      value: `$${metrics?.totalRevenue.toLocaleString() || 0}`,
      icon: <DollarSign className="h-5 w-5 text-blue-500" />
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      {metricCards.map((card, index) => (
        <Card key={index} className="p-6 bg-card dark:bg-gray-800/50 border dark:border-gray-700">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-muted-foreground dark:text-gray-400">{card.title}</p>
              <p className="text-2xl font-semibold mt-1 text-foreground dark:text-white">{card.value}</p>
            </div>
            <div className="p-3 bg-primary/10 dark:bg-primary/5 rounded-full">
              {card.icon}
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};