import { useNavigate } from 'react-router-dom';
import { User, ChevronDown } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { ThemeToggle } from '@/components/theme/ThemeToggle';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface DesktopActionsProps {
  user: any;
  onLogout: () => void;
}

const DesktopActions = ({ user, onLogout }: DesktopActionsProps) => {
  const navigate = useNavigate();

  return (
    <div className="hidden md:flex items-center space-x-4">
      <ThemeToggle />
      {user ? (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="flex items-center gap-2">
              <User className="h-4 w-4" />
              My Profile
              <ChevronDown className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-48">
            <DropdownMenuItem onClick={() => navigate('/wedding-details')}>
              My Wedding
            </DropdownMenuItem>
            <DropdownMenuItem onClick={onLogout}>
              Log Out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ) : (
        <div className="flex items-center gap-4">
          <Button variant="ghost" onClick={() => navigate('/auth/login')}>
            Login
          </Button>
          <Button 
            onClick={() => navigate('/auth/signup')}
            className="bg-gradient-to-r from-secondary to-primary text-white hover:opacity-90"
          >
            Plan Your Big Day
          </Button>
        </div>
      )}
    </div>
  );
};

export default DesktopActions;